import { encode } from '@/utils/base64Url'

/**
 * Matches `/api/v#/foo?query` or `/eco/foo?query` where `?query` is optional.
 *
 * Groups from the above example:
 *   1. `/api/v#` or `/eco`
 *   2. `/foo`
 *   3. `query` (optional)
 */
const apiRequestPathAndQuery = /(^\/(?:api\/v\d|eco))(\/.*?)(?:\?(.*))?$/
const subdomain = window.location.hostname.split('.')[0]
const isImage = (url: string) => /\.(jpg|png|svg)$/.test(url.split('?')[0]!)

/**
 * Matches protocol relative URLs for non-production `storage` bucket names,
 * e.g. `//storage.googleapis.com/pc-eng-stage-storage/` or `//storage.googleapis.com/pc-eng-jon-storage-dev`/.
 */
const nonProductionStorageBucket =
  /\/\/storage\.googleapis\.com(?=\/pc-eng-[a-z]+-storage(-dev)?\/)/

/**
 * Creates an imgix URL from a relative or absolute asset URL.
 * Most image URLs are already relative coming from our JSON data APIs.
 * However, some paths require special resolution logic to distinct buckets, or via proxy to access a subdomain.
 */
export const toImgixUrl = (baseSrc: string) => {
  // baseSrc may be relative or absolute
  if (baseSrc.includes('.imgix.net/')) {
    // Already an imgix URL
    return baseSrc
  }

  if (baseSrc.startsWith('/alc/')) {
    // Alchemy asset
    return `//pc-alc.imgix.net${baseSrc}`
  } else if (nonProductionStorageBucket.test(baseSrc)) {
    // paperculture-image uses path-portal and recognizes storage bucket names for non-production
    return baseSrc.replace(
      nonProductionStorageBucket,
      '//paperculture-image.imgix.net'
    )
  } else if (
    baseSrc.includes('//storage.googleapis.com/storage.paperculture.com')
  ) {
    // pc-storage maps to our production Google Storage bucket for generated images
    return baseSrc.replace(
      '//storage.googleapis.com/storage.paperculture.com',
      '//pc-storage.imgix.net'
    )
  } else if (baseSrc.startsWith('/')) {
    const apiRequestMatches = apiRequestPathAndQuery.exec(baseSrc)
    if (apiRequestMatches && isImage(baseSrc)) {
      const [, apiPrefix, pathRest, query] = apiRequestMatches
      const queryPathSegment = query ? `/q-${encode(query)}` : ''
      return `//paperculture-image.imgix.net/${subdomain}${apiPrefix}${queryPathSegment}${pathRest}`
    } else {
      // Relative URLs are assumed to be on our default domain / www and therefore our default source
      return `//paperculture.imgix.net${baseSrc}`
    }
  } else {
    throw new Error(`baseSrc cannot be converted to an imgix URL: ${baseSrc}`)
  }
}
